import React from "react";
import api from "../../../api";

import { Segment, Table, Pagination, Message, Item } from "semantic-ui-react";
import { DatatableTable } from "./DatatableTable";

import ChatFilter from "./ChatFilter";
import { DatatableRow } from "./DatatableRow";
import { DatatablePageSizeSelect } from "./DatatablePageSizeSelect";
import Modal from "../ChatSetup/components/Modal";
import AdditionalCaptured from "./AdditionalCaptured";

import ViewDetails from "./ViewDetails";
import ViewNotes from "./ViewNotes";

import ChatStatus from "./ChatStatus";
import { CSVLink, CSVDownload } from "react-csv";
import Notes from "./Notes";
import { throwStatement } from "@babel/types";
import DialogBoxHipaaPin from "../DialogBoxHipaaPin";
import config from "../../../config/index";
import axios from "axios";
import ReactAudioPlayer from 'react-audio-player';
import CustomErrorField from "../../Form/CustomErrorField";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomModel from "../CompletedChats/CustomModel";


const tableHeader = [
	{
		title: "Actions",
		width: "1",
		sort: "",
		checkbox: true,
	},
	{
		title: "Date",
		width: "3",
		sort: "created_at",
	},
	{
		title: "From number",
		width: "1",
		sort: "",
	},
	{
		title: "Call transcript",
		width: "2",
		sort: "",
	},
	{
		title: "Call recording",
		width: "1",
		sort: "",
	},
	{
		title: "Summary",
		width: "2",
		sort: "",
	},
	{
		title: "Transcript Status",
		width: "3",
		sort: "chat_status",
	},
	{
		title: "Team Notes",
		width: "3",
		sort: "",
	},
	// {
	// 	title: "Forwarded to human number",
	// 	width: "1",
	// 	sort: "",
	// },
	{
		title: "AI call time",
		width: "1",
		sort: "",
	},
	// {
	// 	title: "Human call time",
	// 	width: "1",
	// 	sort: "",
	// },
	{
		title: "Call Status",
		width: "1",
		sort: "",
	},
	// {
	// 	title: "Name",
	// 	width: "3",
	// 	sort: "name",
	// },

	// {
	// 	title: "Zip code",
	// 	width: "1",
	// 	sort: "",
	// },
	// {
	// 	title: "Email",
	// 	width: "2",
	// 	sort: "email",
	// },
	{
		title: "Parsed Data",
		width: "2",
		sort: "",
	}
];
const queryParams = [
	"_limit",
	"_order",
	"_sort",
	"q",
	"_page",
	"chatstatus",
	"chatfrequency",
	"time_zone",
	"startdaterange",
	"enddaterange",
];

export default class DatatableList extends React.Component {
	constructor(props) {
		
		super(props);
		this.state = {
			lists: [],
			_sort: "id",
			_page: 1,
			_order: "desc",
			_limit: 10,
			q: "",
			chatstatus: "",
			chatfrequency: "",
			startdaterange: "",
			enddaterange: "",
			totalCount: 0,
			loading: false,
			modalstatus: false,
			modaltitle: null,
			modalbuttonmsg: {},
			modalType: null,
			modalfooter: "",
			modalBody: "",
			datatableHeader: null,
			datatableRows: null,
			modalErrorMsg: false,
			modalstatusAddEdit: false,
			modaldata: {},
			modalFormLoader: false,
			selectedOptions: [],
			accessModuleList: [],
			auto_id: "",
			modalTranscript: "",
			csvdata: [],
			hipaaValidateObject: {},
			modalstatusHIPAA: false,
			user: props.user,
			last_call_recording: '',
			reportcommnet: '',
			reporterror: false,
			reportstatus: '',
			modalBtn:true,
			oldTxt:'',
			custom_search:[],
			custom_searchdata:[],
			parsed_data:{},
			openCustomModal:false,
			curid:"",
			existdata:[],
			parsed_config:[],
      		is_required:0
		};
		this.csvLink = React.createRef();
	}

	componentDidMount() {
		this.loadData({});
	}

	static directionConverter(order) {
		if (order === "asc") {
			return "ascending";
		} else if (order === "desc") {
			return "descending";
		} else {
			return null;
		}
	}

	handleSort = (clickedColumn) => {
		const { _sort, _order } = this.state;

		let newOrder = _order === "asc" ? "desc" : "asc";
		if (_sort !== clickedColumn) {
			newOrder = "asc";
		}

		this.loadData({
			_sort: clickedColumn,
			_page: 1,
			_order: newOrder,
		});
	};

	onChangeLimit = (event, data) => {
		if (data.value !== this.state._limit) {
			this.loadData({ _limit: data.value, _page: 1 });
		}
	};

	onSubmitFilter = (filter, val) => {
		
		if (filter == "chatfrequency") {
			this.setState({
				startdaterange: "",
				enddaterange: "",
			});
		}
		//if (filter !== this.state.q) {
		this.loadData({ [filter]: val, _page: 1 });
		// }
	};

	onSubmitFilterDateReange = (parmas) => {
		this.loadData({
			chatfrequency: parmas["chatfrequency"],
			startdaterange: parmas["startdaterange"],
			enddaterange: parmas["enddaterange"],
			_page: 1,
		});
	};

	onChangePage = (event, data) => {
		const { activePage } = data;
		if (activePage !== this.state._page) {
			this.loadData({ _page: activePage });
		}
	};

	modalClose = (flag, fm) => {
		this.setState({
			modalstatus: false,
			modalstatusAddEdit: false,
			modaltitle: "",
			modalmessage: "",
			modalbuttonmsg: {},
			modalType: "",
			modaldata: {},
			selectedOptions: [],
		});
	};

	loadData = (params) => {
		const newState = Object.assign({}, this.state, params, { loading: false });
		queryParams.forEach(function (element) {
			if (!(element in params)) {
				params[element] = newState[element];
			}
		});

		let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
		let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
		let time_zone = clientTimeZone;

		params["time_zone"] = time_zone;

		const esc = encodeURIComponent;
		const query = Object.keys(params)
			.map((k) => esc(k) + "=" + esc(params[k]))
			.join("&");

		let totalCountQuery = "";
		if (params.q !== "") {
			totalCountQuery = `q=${params.q}`;
		}
		this.setState(newState, () => {
			this.setState({ loading: true });
			api.transcriptchatlist.getVoiceChatData(query).then((data) => {
				try {
					let datatableRowstemp;
					this.setState({ lists: data.data.list });

					if (data.data.totalrecord) {
						
						datatableRowstemp = this.state.lists.map((list, index) => (
							<DatatableRow
								channgeEvent={this.checkboxChangeEvent}
								key={index}
								list={list}
								viewAdditionalCaptured={this.viewAdditionalCaptured}
								viewDetails={this.viewDetails}
								viewSummary={this.viewSummary}
								viewRecording={this.viewRecording}
								viewNotes={this.viewNotes}
								viewNoteshtml={this.viewNotesHtml}
								openHipaaRow={this.openHipaaRow}
								viewPaerseData={this.viewPaerseData}
								reGenerateParseData={this.reGenerateParseData}
							/>
						));
					} else {
						datatableRowstemp = (
							<Table.Row>
								<Table.Cell colSpan={7} textAlign="center">
									No record found.
								</Table.Cell>
							</Table.Row>
						);
					}
					this.setState({
						datatableRows: datatableRowstemp,
						totalCount: data.data.totalrecord,
						lists: data.data.list,
					});
				} catch (err) {
					console.log(err);
				}
				const newState = Object.assign({}, this.state, params, {
					loading: false,
				});
				this.setState(newState);
			});
		});
	};
	copyurl = (linkurl, logid = '') => {
		const base_url = config.serverBaseURL;
        let externalLink = `${base_url}/voice/recording/${btoa(logid)}`;
		navigator.clipboard.writeText(externalLink);
		const copyp = document.getElementById('copyurlsuccess');
		copyp.style.display = 'block';
		setTimeout( () => {
			copyp.style.display = 'none';
		}, 2000);
	}
	viewRecording = (rsid, call_recording_sid) => {
		if (!rsid) {
			this.setState({
				loading: false,
				modalstatus: true,
				modaltitle: "",
				modalfooter: "",
				modalbody: (
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<span style={{ fontWeight: 600, fontSize: 14, color: 'red' }}>Call recording not available.</span>
					</div>

				),
				modalTranscript: false
			});
			return false;
		}
		try {
			const base_url = config.serverBaseURL;
        	let externalLink = `${base_url}/voice/recording/${btoa(call_recording_sid)}`;

			this.setState({
				last_call_recording: `recording_play_${call_recording_sid}`,
				loading: false,
				modalstatus: true,
				modaltitle: "",
				modalfooter: "",
				modalbody: (
					<>
						<div style={{display: 'flex', justifyContent: 'space-between', columnGap: 20}}>
							<div class="box-title">Call recording</div>
							<div class="box-title" id="copyurlsuccess" style={{display: 'none', color: 'green'}}>URL Copied</div>
						</div>
						<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
						<div onClick={ () => this.copyurl(rsid, call_recording_sid)} title="Copy" className="alert" style={{backgroundColor: 'aliceblue', cursor: 'pointer', overflowWrap: 'anywhere', alignItems: 'center', columnGap: 14}}>
							{externalLink} <i className="fa fa-copy"></i>
						</div>
						<audio key={`recording_play_${call_recording_sid}`} id={`recording_play_${call_recording_sid}`} controls>
							<source src={`${rsid}`} />
							<source src={`${rsid}`} />
							Your browser does not support the audio element.
						</audio>
					</div>
					</>
					
				),
				modalTranscript: false
			});
		} catch (err) { }
	}

	viewDetails = (auto_id) => {
		let recordlist = this.state.lists.findIndex(
			(list) => list.auto_id === auto_id
		);

		api.transcriptchatlist.viewVoiceChatDetails({ auto_id: auto_id, type: 'question_answer' }).then((data) => {
			try {
				this.setState({
					loading: false,
					modalstatus: true,
					modaltitle: "",
					modalfooter: "",
					auto_id: auto_id,
					custom_search:data.custom_search,
					custom_searchdata:data.search_data,
					parsed_data:data.parsed_data,
					curid:auto_id,
					existdata:data.exist_data,
					parsed_config:data.parsedconfig,
					is_required:data.is_required, 
					modalbody: (
						<ViewDetails
							detaildata={data.data.data}
							auto_id={auto_id}
							updatenote={this.updateNote}
							custom_search={data.data.custom_search}
							openCustomPopup={this.openCustomPopup}
						></ViewDetails>
					),
					modalTranscript: (
						<ChatStatus
							chatRef="live_chat_log"
							auto_id={auto_id}
							selected_chat={data.data.chatstatus}
							list={[
								"Change Status",
								"Customer Contacted",
								"Customer Cancelled",
								"Customer Serviced",
							]}
						/>
					),
				});
			} catch (err) { }
		});
	};

	getDetails = (auto_id) => {
		this.setState({loading:true});	
		let recordlist = this.state.lists.findIndex(
			(list) => list.auto_id === auto_id
		);

		api.transcriptchatlist.viewVoiceChatDetails({ auto_id: auto_id, type: 'question_answer' }).then((data) => {
			try {
				if(data.status){
					this.setState({
						loading: false,
						custom_search:data.custom_search,
						custom_searchdata:data.search_data,
						parsed_data:data.parsed_data,
						curid:auto_id,
						existdata:data.exist_data,
						
					});
                  return true;
				}
					return false;
				
			} catch (err) {
				return false;
			 }
		});
	};

	reGenerateParseData = (logid) => {
		this.setState({
			loading : true
		})
		api.transcriptchatlist.reGenerateParseData({logid: logid}).then((res) => {
			console.log(res);
			if (res.status) {
				const allList = this.state.lists;
				const findIndex = allList.findIndex((item, index) => item.auto_id == logid);
				allList[findIndex].custom_capture = res.parse_data;
				this.setState({
					lists: allList,
					loading : false				
				});
			}else{
				this.setState({					
					loading : false				
				});
			}						
			toast.success(res.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",

            });			
			
		}).catch((err) => {
			console.log(err);
			this.setState({
				loading : false
			})
		})
	}

	viewPaerseData = (data,id) => {
		this.setState({loading:true});
		if (data) {
			try {
				data = JSON.parse(data);
			} catch (error) {
				data = {}
			}
			data = data == null ? {} : data;

			api.transcriptchatlist.viewVoiceChatDetails({ auto_id: id, type: 'question_answer' }).then((data1) => {
				try {
					
					let stateobj={
						loading: false,
						modalstatus: true,
						modaltitle: "",
						modalfooter: "",
						custom_search:data1.custom_search,
						custom_searchdata:data1.search_data,
						parsed_data:data1.parsed_data,
						curid:id,
						existdata:data1.exist_data,
						parsed_config:data1.parsedconfig,
          				is_required:data1.is_required, 
						modalbody: (
							Object.keys(data).length  ? 
							<Table id="additional_captured">
								<Table.Body>
									{Object.keys(data).map(function(key,index){
										return (
											<Table.Row>
												<Table.Cell style={{textTransform:"capitalize"}}>{key}</Table.Cell>
												<Table.Cell>{(typeof data[key] == 'string') ? data[key] : JSON.stringify(data[key])}</Table.Cell>
											</Table.Row>
											)
										}) 
									}
								</Table.Body>
							</Table> : <>
							<h2>No data available.</h2>
							</>
						),
						modalTranscript: false
					};
					
					this.setState(stateobj,() => console.log(this.state.modalbody));
					return false;
					
				} catch (err) {
					
				 }
			});
		     
		}else{
			this.setState({
				loading: false,
				modalstatus: true,
				modaltitle: "",
				modalfooter: "",
				modalbody: (
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<span style={{ fontWeight: 600, fontSize: 14, color: 'red' }}>No data available.</span>
					</div>

				),
				modalTranscript: false
			});
			return false;
		}
	}
	
	viewSummary = (auto_id) => {
		api.transcriptchatlist.viewVoiceChatDetails({ auto_id: auto_id, type: 'summary' }).then((data) => {
			try {
				this.setState({
					loading: false,
					modalstatus: true,
					modaltitle: "",
					modalfooter: "",
					modalbody: (
						<ViewDetails
							detaildata={data.data.data}
							auto_id={auto_id}
							summary={true}
						></ViewDetails>
					),
					modalTranscript: false,
				});
			} catch (err) { }
		});
	}

	deleteNotes = ( id ) => {
		api.transcriptchatlist.deleteNotes({ auto_id: id }).then((data) => {
			
			this.setState({
				loading: false,
				modalstatus: false,
				modaltitle: "Team Notes",
			},this.loadData({}))
		});
		
	}
	viewNotesHtml = async (auto_id) => {
		let recordlist = this.state.lists.findIndex(
			(list) => list.auto_id === auto_id
		);
		api.transcriptchatlist.fetchparticularNotes({ auto_id: auto_id }).then((data) => {
			this.setState({
				loading: false,
				modalstatus: true,
				modaltitle: "Team Notes",
				modalfooter: "",
				modalbody: (
					<ViewNotes
						auto_id={auto_id}
						data={data}
						comapny={this.state.lists[recordlist].company}
						chatlogo={this.state.lists[recordlist].chatLogo}
						deleteNotes = {this.deleteNotes}
					></ViewNotes>
				),
				modalTranscript: false,
			});
		})
		
		
	}

	updateNote = (auto_id, value) => {
		

		let datalist = this.state.lists;

		let recordlist = this.state.lists.findIndex(
			(list) => list.auto_id === auto_id
		);

		datalist[recordlist]["team_notes"] = value;
		this.setState({
			datatableRows: datalist.map((list, index) => (
				<DatatableRow
					channgeEvent={this.checkboxChangeEvent}
					key={index}
					list={list}
					viewAdditionalCaptured={this.viewAdditionalCaptured}
					viewDetails={this.viewDetails}
					viewSummary={this.viewSummary}
					viewRecording={this.viewRecording}
					viewNoteshtml={this.viewNotesHtml}
					openHipaaRow={this.openHipaaRow}
					reGenerateParseData={this.reGenerateParseData}
				
				/>
			)),
			lists: datalist,
		});
	};

	viewAdditionalCaptured = (auto_id) => {
		let recordlist = this.state.lists.findIndex(
			(list) => list.auto_id === auto_id
		);

		let modaldata = this.state.lists[recordlist]["additionl_captured"];

		this.setState({
			loading: false,
			modalstatus: true,
			modaltitle: "Additional Captured Fields",
			modalfooter: "",
			modalbody: (
				<AdditionalCaptured
					additional_captured={modaldata}
				></AdditionalCaptured>
			),
			modalTranscript: false,
		});
	};

	viewNotes = (auto_id) => {
		try {
			let notes_data = "";
			this.setState({
				loading: false,
				modalstatus: true,
				modaltitle: "Team Notes",
				modalfooter: "",
				modalbody: (
					<Notes
						auto_id={auto_id}
						commentform="false"
						tableref="chat_log"
						updatenote={this.updateNote}
					></Notes>
				),
				modalTranscript: false,
			});
		} catch (err) { }
	};

	modalFooter = (type) => { };

	modalClose = () => {
		const recordingDiv = document.getElementById(this.state.last_call_recording);
		if (recordingDiv) {
			
			recordingDiv.pause();
			// recordingDiv.src = '';
		}
		this.setState({
			modalstatus: false,
			parse_data:[],
			custom_search:[],
			custom_searchdata:[],
			
		});
	};

	modalFooter = (id, type) => {
		return (
			<div className="modal-footer">
				<button
					type="button"
					onClick={() => this.modalClose()}
					className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
					data-dismiss="modal"
				>
					<span>No</span>
				</button>
				<button
					type="button"
					onClick={() => this.confirmDelete(this.state.modaldata.id)}
					className="btn linear-gradient yr-submitbtn btn-sm"
				>
					<span>Yes</span>
				</button>
			</div>
		);
	};

	modalBody = () => {
		if (this.state.modalType == "job_description") {
			this.getJobDescriptionView(this.state.auto_id);
		}

		if (this.state.modalType == "additional_captured") {
			this.getAdditionalCapturedView(this.state.auto_id);
		}

		if (this.state.modalType == "notes") {
			this.getTeamNotesView(this.state.auto_id);
		}
	};

	getJobDescriptionView = (id) => { };
	getAdditionalCapturedView = (id) => {
		let recordlist = this.state.lists.findIndex((list) => list.auto_id === id);
		
		let modaldata = this.state.lists[recordlist]["additionl_captured"];
		
		Object.keys(modaldata).map(function () { });
	};
	getTeamNotesView = (id) => { };

	downloadCSV = (event, done) => {

		let params = {};
		params["chatstatus"] = this.state.chatstatus;
		params["chatfrequency"] = this.state.chatfrequency;
		params["strsearch"] = this.state.q;
		params["startdaterange"] = this.state.startdaterange;
		params["enddaterange"] = this.state.enddaterange;

		let list = this.state.lists;
		let selected_arr = [];
		list.filter(function (key) {
			if (key["checked"] == true) {
				selected_arr.push(key["auto_id"]);
			}
		});

		this.setState({ loading: true });

		params["selectallcheckbox"] = selected_arr.length > 0 ? "false" : "true";
		params["chatidscheck"] = JSON.stringify(selected_arr);
		api.transcriptchatlist.getVoiceCSVData(params).then((data) => {
			try {
				this.setState(
					{
						csvdata: data.data.data,
					},
					function () {
						setTimeout(
							function () {
								document.querySelector("#phonechatcsv").click();
								this.setState({ loading: false });
							}.bind(this),
							1000
						);
					}
				);
			} catch (err) { }
		});
	};

	openCustomPopup=(search)=>{
		this.setState({openCustomModal:true,modalstatus:false});
	  }
	
	  closeCustomPopup=()=>{
		this.setState({openCustomModal:false,parsed_data:[],custom_search:[],custom_searchdata:[],curid:"",existdata:[]});
	  }

	checkAll = (e) => {
		let datalist = this.state.lists;
		datalist.map(function (list, index) {
			list["checked"] = e.target.checked;
		});
		this.setState({
			datatableRows: datalist.map((list, index) => (
				<DatatableRow
					channgeEvent={this.checkboxChangeEvent}
					key={index}
					list={list}
					viewAdditionalCaptured={this.viewAdditionalCaptured}
					viewDetails={this.viewDetails}
					viewSummary={this.viewSummary}
					viewRecording={this.viewRecording}
					viewNotes={this.viewNotes}
					viewNoteshtml={this.viewNotesHtml}
					openHipaaRow={this.openHipaaRow}
					reGenerateParseData={this.reGenerateParseData}

				/>
			)),
			lists: datalist,
		});
	};

	checkboxChangeEvent = (auto_id) => {
		let datalist = this.state.lists;
		let recordlist = datalist.findIndex((list) => list.auto_id === auto_id);

		if (datalist[recordlist]["checked"]) {
			datalist[recordlist]["checked"] = false;
		} else {
			datalist[recordlist]["checked"] = true;
		}

		this.setState({
			datatableRows: datalist.map((list, index) => (
				<DatatableRow
					channgeEvent={this.checkboxChangeEvent}
					key={index}
					list={list}
					viewAdditionalCaptured={this.viewAdditionalCaptured}
					viewDetails={this.viewDetails}
					viewSummary={this.viewSummary}
					viewRecording={this.viewRecording}
					viewNotes={this.viewNotes}
					viewNoteshtml={this.viewNotesHtml}
					openHipaaRow={this.openHipaaRow}
					reGenerateParseData={this.reGenerateParseData}

				/>
			)),
			lists: datalist,
		});

		//  let array=this.state.checkedboxes;
		// const index = this.state.checkedboxes.indexOf(auto_id);
		// if (index > -1) {
		//   array.splice(index, 1);
		// }else{
		//   array.push(auto_id)
		// }

		// this.setState({
		//   checkedboxes:array
		// });
	};

	openHipaaRow = (auto_id) => {
		this.setState({ loading: true })
		api.manageUser.checkHipaaAccount({ user_id: this.props.user.user_id, email: this.props.user.email }).then((data) => {
			try {
				if (data.data.data.subaccount_id == "found") {
					var type = "validate";
				} else {
					var type = "register";
				}
				this.setState({
					loading: false,
					modalstatusHIPAA: true,
					modaltitle:
						"To view sensitive chat information, please enter your authorize email and PIN",
					hipaaValidateObject: {
						userId: this.props.user.user_id,
						userType: "User",
						via: "user",
						auto_id: auto_id,
						pin_email: this.props.user.email,
						refId: auto_id,
						chat_section: "voicecalllist",
						type: type

					},
				});
			} catch (err) { }
		});


	};

	openHipaaAfterRegister = auto_id => {
		this.setState({
			loading: false,
			modalstatusHIPAA: true,
			modaltitle:
				"To view sensitive chat information, please enter your authorize email and PIN",
			hipaaValidateObject: {
				userId: this.props.user.user_id,
				userType: "User",
				via: "user",
				auto_id: auto_id,
				pin_email: this.props.user.email,
				refId: auto_id,
				chat_section: "chatlist",
				type: "validate"

			},
		});
	}

	onValidHipaaSubmit = (auto_id) => {
		let datalist = this.state.lists;

		let recordlist = this.state.lists.findIndex(
			(list) => list.auto_id === auto_id
		);


		datalist[recordlist]["hippaOn"] = "none";
		this.setState({
			datatableRows: datalist.map((list, index) => (
				<DatatableRow
					channgeEvent={this.checkboxChangeEvent}
					key={index}
					list={list}
					viewAdditionalCaptured={this.viewAdditionalCaptured}
					viewDetails={this.viewDetails}
					viewSummary={this.viewSummary}
					viewRecording={this.viewRecording}
					viewNotes={this.viewNotes}
					viewNoteshtml={this.viewNotesHtml}
					openHipaaRow={this.openHipaaRow}
					viewPaerseData={this.viewPaerseData}
					reGenerateParseData={this.reGenerateParseData}
				/>
			)),
			lists: datalist,
		});
		this.setState({
			chat: this.state.chat,
			chatLeftList: this.state.chatLeftList,
			modalstatusHIPAA: false,
			name: this.state.name,
		});
	}

	hipaaModalClose = e => {
		this.setState({
			modalstatusHIPAA: false,
		});
	}

	handleChangereport=(e)=>{
		let btn=true;
		let newtxt=e.target.value.trim();
		if(newtxt!==this.state.oldTxt){
		   btn=false;
		}
		if(newtxt.length===0){
			btn=true;
		}
		
		this.setState({reportcommnet:newtxt,reporterror:false,modalBtn:btn},()=>{ this.updatemodelBody();});
	  }

	updatemodelBody = () => {
		let body = (<form id="notesForm1" method="post">
			<Message
				color="teal"
				style={{
					display: this.state.reportstatus?.length ? "block" : "none",
					marginTop: 30,
				}}
			>
				{this.state.reportstatus}
			</Message>
			Please provide additional details why this chat needs training?
			<div className="form-group">

				<textarea rows="5" onChange={(e) => this.handleChangereport(e)} name="reportcommnet" id="notes" placeholder="Write notes here ..." defaultValue={this.state.reportcommnet} className={
					this.state.reporterror
						? "form-control is-invalid"
						: "form-control"}></textarea>
				{this.state.reporterror && <CustomErrorField
					message="This field is required"
					id="help-error"
					className="error invalid-feedback"
				/>
				}
			</div>
			<div className="form-group">
			<button  disabled={this.state.modalBtn} type="button" 
			onClick={(e)=>{ this.setState({modalBtn:true},()=>{
            this.updatemodelBody();
          });
          this.saveReport(e)}} 
		   style={{
					width: '100%',
					marginTop: '20px'
				}} className="btn linear-gradient yr-submitbtn btn-md sbold btn-block">Submit</button>
			</div>

		</form>);
		this.setState({ modalbody: body, loading: false });
		return body;
	}

	saveReport = (e) => {
		e.preventDefault();
		let note = this.state.reportcommnet.trim();
		this.setState({ loading:true,modalBtn:true })
		if (note.length != 0) {
			api.chatlist.savechatReportVoice({ id: this.state.auto_id, report: note }).then((data) => {
				try {
					if (data.status) {
						this.setState({ reportcommnet: '', auto_id: '', reporterror: false, modaltitle: "Report Chat For Training", reportstatus: data.message, loading: false,oldTxt:note });
						setTimeout(() => {
							this.setState({ modalstatus: false, reportstatus: "" })
						}, 3000);
					}
					this.updatemodelBody();
				} catch (err) {
				}

			});
		} else {
			this.setState({ reporterror: true, loading: false })
			this.updatemodelBody();
		}
	}

	showtrainlogModal = (id) => {
		this.setState({ loading: true });
		api.chatlist.getTrainingLogVoice("id=" + id).then((data) => {
			try {
				let msg = data.data;
				this.setState({ reportcommnet: msg, loading: false,oldTxt:msg });
				let notes_data = "";
				let body = this.updatemodelBody();
				this.setState({
					loading: false,
					modalstatus: true,
					modaltitle: "Report Chat For Training",
					modalfooter: "",
					modalbody: body,
					reporterror: false,
					modalTranscript: false,
				});

			} catch (err) { }
		});
	}

	render() {
		
		return (
			<div>
				<ChatFilter
					filter={{
						chatstatus: this.state.chatstatus,
						chatfrequency: this.state.chatfrequency,
						strsearch: this.state.q,
						startdaterange: this.state.startdaterange,
						enddaterange: this.state.enddaterange,
					}}
					totalCount={this.state.totalCount}
					onSubmitFilter={this.onSubmitFilter}
					loading={this.state.loading}
					onSubmitFilterDateReange={this.onSubmitFilterDateReange}
					csvdata={this.state.csvdata}
					dowloadcsv={this.downloadCSV}
				/>
				<Segment style={{ width: "100%",maxWidth: "calc(96vw)" }}>
					{this.state.loading && <div className="ui loading form"></div>}

					<DatatableTable
						lists={this.state.lists}
						datatableHeader={tableHeader}
						datatableRows={this.state.datatableRows}
						totalCount={this.state.totalCount}
						totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
						currentPage={this.state._page}
						onChangePage={this.onChangePage}
						column={this.state._sort}
						direction={DatatableList.directionConverter(this.state._order)}
						handleSort={this.handleSort}
						onChangeLimit={this.onChangeLimit}
						limit={this.state._limit.toString()}
						checkAll={this.checkAll}
					/>
				</Segment>
				<DatatablePageSizeSelect
					limit={this.state._limit.toString()}
					onChangeLimit={this.onChangeLimit}
				/>{" "}
				Showing 1 - {this.state._limit.toString()} of {this.state.totalCount}
				<Pagination
					totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
					activePage={this.state._page}
					onPageChange={this.onChangePage}
				/>
				<Modal
					show={this.state.modalstatus}
					footer={this.state.modalfooter}
					onclose={this.modalClose}
					title={this.state.modaltitle}
					body={this.state.modalbody}
					modaltranscript={this.state.modalTranscript}
					isgpt={true}
					showtrainlogModal={this.showtrainlogModal}
					auto_id={this.state.auto_id}
					loading={this.state.loading}
					custom_search={this.state.custom_search}
                    openCustomPopup={this.openCustomPopup}
					parsed_data={typeof this.state.parsed_data!="undefined" ? this.state.parsed_data : {}}
				/>
				<CSVLink
					id="phonechatcsv"
					data={this.state.csvdata}
					filename="phonechat.csv"
					className="hidden"
					target="_blank"
				/>
				<DialogBoxHipaaPin
					email={this.props.user.email}
					obj={this.state}
					modalClose={this.hipaaModalClose}
					onValidHipaaSubmit={this.onValidHipaaSubmit}
					openHipaaAfterRegister={this.openHipaaAfterRegister}
				/>
				<ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
				{(typeof this.state.custom_search!="undefined" && typeof this.state.custom_searchdata!="undefined" && typeof this.state.parsed_data!="undefined" )  && <CustomModel
				show={this.state.openCustomModal}
				modalClose={this.closeCustomPopup}
				custom_search={this.state.custom_search}
				custom_searchdata={this.state.custom_searchdata}
				parsed_data={this.state.parsed_data}
				exist_data={this.state.existdata}
				title="Push Data Manually"
				curid={this.state.curid}
				module='voice'
				parsed_config={this.state.parsed_config}
				is_required={this.state.is_required}
				/>}
			</div>
		);
	}
}
